<template>
  <div class="searchImage">
    <div class="box">
      <div class="left">
        <div class="main" v-show="!isShowHistory">
          <div class="search_box">
            <div class="search">
              <input
                class="inp"
                v-model="searchValue"
                type="text"
                @focus="handleFocus"
                @blur="handleBlur"
                :placeholder="placeholderText"
                maxlength="75"
              />
              <div class="search_btn" @click="handleClick">
                <img
                  :src="require('assets/images/included_search.png')"
                  alt=""
                />
              </div>
            </div>
            <div class="history" @click="handleClickHistory">
              <img :src="require('assets/images/ai_history.png')" alt="" />
            </div>
          </div>
          <div class="content">
            <div
              class="sidebar"
              :class="{ noData: sidebarList.length == 0 && !isShowAi }"
              v-if="!isShowAi"
            >
              <el-menu class="el-menu-vertical-demo">
                <el-menu-item
                  v-for="(item, index) in sidebarList"
                  @click.native="findClassfiy(item)"
                  :key="index"
                  :index="item"
                  >{{ item }}</el-menu-item
                >
              </el-menu>
            </div>
            <div class="imgList">
              <div class="tips">
                图片素材的版权归原创作者所有，用户需依法合理使用图片素材。
              </div>
              <div class="images" id="con">
                <div
                  class="item"
                  v-for="(item, index) in imagesData"
                  :key="index"
                >
                  <img :src="item.middleURL" alt="" />
                  <div class="backgord">
                    <div class="view" @click="viewOnline(item)">查看</div>
                    <!-- <div class="inset" @click="inset(item)">插入</div> -->
                    <div class="download" @click="download(item)">下载</div>
                  </div>
                </div>
              </div>
              <div class="kong" v-show="ishowKong">
                <img :src="require('assets/images/sstp.png')" />
                <div>输入关键词，网络搜索相关主题图标素材</div>
              </div>
            </div>
          </div>
        </div>
        <History
          @viewImage="viewImageChild"
          @back="back"
          ref="History"
          v-if="isShowHistory"
        />
      </div>

      <div class="right">
        <recommend />
      </div>
    </div>

    <div class="commonFooter_box">
      <commonFooter />
    </div>
    <el-dialog title="预览" class="viewImage" :visible.sync="viewImage">
      <img :src="viewImagePath" alt="" />
    </el-dialog>
  </div>
</template>

<script>
import axios from "axios";
import History from "./historyAi";
import { getToken } from "utils/auth";
import { createAiLoading } from "utils/utils";
import { prompt, findById, remainingQuantity } from "api/searchImage";
import recommend from "components/recommend/recommend.vue";
import commonFooter from "components/footer/commonFooter.vue";
export default {
  name: "searchImage",
  components: {
    commonFooter,
    recommend,
    History,
  },
  data() {
    return {
      sidebarList: [],
      currentTips: {},
      isShow: false,
      isShowHistory: false,
      searchValue: "",
      currentItem: "图片推荐",
      imagesData: [],
      currentKey: "",
      viewImage: false,
      viewImagePath: "",
      page: 1,
      size: 10,
      currtentItem: "",
      placeholderText: "请输入关键词",
      turn: false,
      isShowAi: false,
      aiImgArr: [],
      timer: null,
      loading: null,
      showEndBtn: false,
      rankTips: [],
      TimeImg: 0,
    };
  },
  created() {
    // this.getTimeImg();
  },
  computed: {
    ishowKong() {
      if (this.turn) {
        if (this.aiImgArr.length == 0) {
          return true;
        } else {
          return false;
        }
      } else {
        if (this.imagesData.length == 0) {
          return true;
        } else {
          return false;
        }
      }
    },
  },
  mounted() {
    let searchImage = document.querySelector(".main");
    let _this = this;
    searchImage.onscroll = function (e) {
      if (_this.turn) {
        return;
      }
      if (
        searchImage.scrollHeight - searchImage.scrollTop ===
        searchImage.clientHeight
      ) {
        _this.page++;
        _this.getImageUrl(_this.currentKey + _this.currtentItem, 1, 1);
      }
    };

    window.slipperyRecommendImg = (content) => {
      //alert(content);
      this.searchValue = content;
      this.currentKey = content;
      this.getImageUrl(this.currentKey, 0, 0);
    };
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
  methods: {
    viewImageChild(item) {
      this.viewImagePath = item;
      this.viewImage = true;
    },
    back() {
      this.isShowHistory = false;
    },
    handleClickHistory() {
      if (!getToken("auth_token")) {
        this.$message({
          message: "请先登录！",
          duration: 1500,
        });

        return window.loginFun();
      }
      // this.endAI();
      this.currentItem = "历史AI画作";
      this.isShowHistory = true;
      this.$refs["History"].findPageList();
    },
    aiRecommendClick() {
      this.currentItem = "图片推荐";
      this.turn = false;
      this.isShowHistory = false;
      this.isShowAi = false;
      try {
        // eslint-disable-next-line
        RecommendImg();
      } catch (e) {
        console.log(e);
      }
    },
    //插入图片
    inset(item) {
      try {
        // eslint-disable-next-line
        InsertImage(item.middleURL);
      } catch (e) {
        console.log(e);
      }
    },
    UpLevelAfter() {
      this.isShow = false;
    },
    view(item) {
      this.viewImagePath = item.response;
      this.viewImage = true;
    },
    insertBase64(item) {
      console.log(item);
      //https://fafs.antiplagiarize.com/group1/M00/0D/08/L2pUHWMyoDmAHaiGAAalw4nWSDw400.png
      try {
        // eslint-disable-next-line
        InsertImage(item.response);
      } catch (e) {
        console.log(e);
      }
    },
    DownloadBase64Image(item) {
      console.log(item);
      const name = item.response.split("/").pop();
      const suffix = name.substring(name.indexOf("."));
      // searchValue
      console.log(item.sourceKey + suffix);
      try {
        // eslint-disable-next-line
        DownloadImage(item.sourceKey + suffix, item.response);
      } catch (e) {
        console.log(e);
      }
    },
    handleFocus() {
      this.placeholderText = "";
    },
    handleBlur() {
      this.placeholderText = "让每个人都写得更好";
    },
    switchChangeOther() {
      this.currentItem = "AI绘画";
      this.isShowAi = true;
      this.isShowHistory = false;
      this.turn = true;

      // if (this.turn) {
      //   localStorage.setItem("turn", "1");
      // } else {
      //   localStorage.setItem("turn", "2");
      // }
    },
    viewOnline(item) {
      this.viewImagePath = item.middleURL;
      this.viewImage = true;
    },
    download(item) {
      console.log(item.fromPageTitleEnc + "." + item.type);
      console.log(item.middleURL);
      // try {
      //   // eslint-disable-next-line
      //   DownloadImage(item.fromPageTitleEnc + "." + item.type, item.middleURL);
      // } catch (e) {
      //   console.log(e);
      // }
      window.open(
        item.middleURL + "?attname=" + item.fromPageTitleEnc + "." + item.type
      );
    },
    async handleClick() {
      if (this.searchValue == "") {
        return this.$message.error("请输入搜索框内容");
      }
      this.currentKey = this.searchValue;
      this.currtentItem = "";
      if (!this.turn) {
        this.getImageUrl(this.searchValue, 0, 0);
      } else {
        try {
          this.loading = createAiLoading(".main");
          // this.aiImgArr.push("");
          this.aiImgArr = []; //清空当前生成的图片
          this.aiImgArr.push(""); // 隐藏缺省页
          this.showEndBtn = true;
          const res = await prompt(this.searchValue);
          if (res.code == 200) {
            this.getTimeImg();
            this.rankTips = res.data.messageData.split(",");
            this.timer = setInterval(async () => {
              const result = await findById(res.data.id);
              if (result.data.status == 2) {
                const _temp = result.data.response.split(",");
                this.loading.close();
                this.showEndBtn = false;
                // this.aiImgArr = _temp.map((item) => {
                //   return "https://fafs.antiplagiarize.com/" + item;
                // });
                result.data.response =
                  "https://fafs.antiplagiarize.com/" + result.data.response;

                this.aiImgArr = [result.data];

                if (this.aiImgArr.length != 0) {
                  this.isShowAi = true;
                }
                clearInterval(this.timer);
              } else if (result.data.status == 3) {
                this.loading.close();
                clearInterval(this.timer);
                this.showEndBtn = false;
                this.aiImgArr = [];
                this.$message.error("生成失败!");
              }
            }, 8000);
          } else if (res.code == 8302) {
            this.aiImgArr = [];
            // this.$message.error(res.message);

            this.loading.close();
            this.showEndBtn = false;
            this.currentTips = res.data;
            this.isShow = true;
          } else {
            this.aiImgArr = [];
            this.loading.close();
            this.showEndBtn = false;
            this.$message.error(res.message);
          }

          // this.aiImgArr = res.data.content;
        } catch (e) {
          this.aiImgArr = [];
          this.loading.close();
          this.showEndBtn = false;
        }
      }
    },

    findClassfiy(item) {
      if (item == "全部") {
        this.currtentItem = "";
        this.getImageUrl(this.currentKey, 1, 0);
      } else {
        this.currtentItem = item;
        this.getImageUrl(this.currentKey + item, 1, 0);
      }
    },
    // key 关键字  flag 是否需要请求侧边栏  isconcat是否重新请求
    getImageUrl(key, flag, isconcat) {
      if (isconcat == 0) {
        this.page = 2;
      }
      // https://image.baidu.com/search/acjson?tn=resultjson_com&word=%E8%9B%87&queryWord=%E8%9B%87
      //
      let time = new Date().getTime();
      axios({
        method: "get",
        url: `/cheerioImg/search/acjson?tn=resultjson_com&logid=8831048469171882649&ipn=rj&ct=201326592&is=&fp=result&fr=&word=${key}&queryWord=${key}&cl=2&lm=-1&ie=utf-8&oe=utf-8&adpicid=&st=-1&z=&ic=&hd=&latest=&copyright=&s=&se=&tab=&width=&height=&face=0&istype=2&qc=&nc=1&expermode=&nojc=&isAsync=&pn=150&rn=${
          this.page * this.size
        }&gsm=1e0000000000001e&${time}=`,
        //请求的链接地址
        // responseType: "document",
        headers: {},
        params: {},
      }).then((res) => {
        // const data = JSON.parse(res.data);
        console.log(res.data.data);
        if (isconcat == 1) {
          this.imagesData = this.imagesData.concat(res.data.data);
        } else {
          this.imagesData = res.data.data;
          if (this.imagesData.length != 0) {
            this.isShowAi = false;
          } else {
            this.isShowAi = true;
          }
        }

        // this.$nextTick(() => {
        //   this.setImageStyle();
        // });
      });
      // if(){

      // }
      if (flag == 0) {
        // axios({
        //   method: "get",
        //   url:
        //     "/cheerioImg/index?tn=baiduimage&ipn=r&ct=201326592&cl=2&lm=-1&st=-1&fm=result&fr=&sf=1&fmq=1660793684835_R&pv=&ic=0&nc=1&z=&hd=&latest=&copyright=&se=1&showtab=0&fb=0&width=&height=&face=0&istype=2&dyTabStr=&ie=utf-8&sid=&word=" +
        //     key, //请求的链接地址
        //   responseType: "document",
        //   headers: {
        //     Accept:
        //       "text/html,application/xhtml+xml,application/xml;q=0.9,image/avif,image/webp,image/apng,*/*;q=0.8,application/signed-exchange;v=b3;q=0.9",
        //     // Host: "image.baidu.com",
        //   },
        //   params: {},
        // }).then((res) => {
        //   const menuList = res.data.querySelectorAll(
        //     "#topRS .pull-rs .pull-rs-p"
        //   );
        //   this.sidebarList = [];
        //   for (let i = 0; i < menuList.length; i++) {
        //     this.sidebarList.push(menuList[i].innerText);
        //   }
        // });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.commonFooter_box {
  margin-top: 20px;
  width: 100%;
}
.searchImage {
  width: 100%;
  position: absolute;
  left: 0;
  margin-top: -25px;
  padding: 25px 0 0 0;
  background-color: #f9f9f9;
  .box {
    display: flex;
    width: 1338px;
    box-sizing: border-box;
    margin: 0 auto;
    .left {
      width: 936px;
      margin-right: 20px;
      background-color: #fff;
      display: flex;
      position: relative;
    }
    .right {
      background-color: #fff;
      flex: 1;
    }
  }
  .rankTips {
    position: absolute;
    left: 50%;
    top: 40%;
    transform: translateX(-50%);
    z-index: 9999;
    font-size: 14px;
    color: #999;
    line-height: 25px;
    text-align: center;
  }

  .end {
    width: 80px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    background: #409eff;
    color: #fff;
    margin: 10px auto;
    font-size: 14px;

    border-radius: 5px;

    cursor: pointer;
  }

  .OfficeNav_box {
    position: relative;
    .time {
      cursor: pointer;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 20px;
      display: flex;
      align-items: center;
      font-size: 14px;

      .img {
        margin: 0 5px;
      }

      .text {
        color: #4587ff;
        font-weight: bold;
      }
    }
  }

  .viewImage {
    ::v-deep .el-dialog {
      width: 700px;
      .el-dialog__body {
        img {
          width: 100%;
        }
      }
    }
  }
  .aiImg_generate {
    display: flex;
    flex-direction: column;
    width: 60%;
    margin: 0 auto;

    .aiImg_item {
      position: relative;
      flex: 1;
      margin: 30px;
      img {
        width: 100%;
      }
      .option {
        padding: 0 0 0 20px;
        box-sizing: border-box;
        bottom: 0;
        width: 100%;
        height: 0px;
        position: absolute;
        display: flex;
        align-items: center;
        justify-content: space-between;
        transition: all 0.3s;
        background-color: rgba($color: #000000, $alpha: 0.7);
        overflow: hidden;
        opacity: 0;
        color: #409eff;
        .option_items {
          display: flex;
          align-items: center;
          > div {
            margin: 0 10px;
            display: flex;
            align-items: center;
          }
        }
        .name {
          color: #fff;
        }
        .view {
          // background: #409eff;
          color: #409eff;
        }
        .inset {
          // background: #409eff;
          color: #409eff;
        }
        > div {
          // background: #fff;
          display: flex;
          align-items: center;
          img {
            width: 16px;
            height: 16px;
          }
          cursor: pointer;
          // width: 40px;
          // height: 40px;
          font-size: 14px;
          // text-align: center;
          // line-height: 40px;
          // border-radius: 50%;
        }
      }
      &:hover .option {
        height: 40px;
        opacity: 1;
      }
    }
  }
  .office-nav {
    @include flex-start;
    width: 100vw;
    // margin-left: -30px;
    .switch,
    .history {
      @include flex-start;
      display: inline-flex;
      margin-left: 30px;
      // margin-right: 20px;
      cursor: pointer;
      .txt {
        font-size: 13px;
        font-weight: 500;
        // color: #000;
        margin-left: 5px;
      }
    }
    .isActive {
      color: #409eff !important;
    }
    .ai {
      cursor: pointer;
      margin-left: 20px;
      font-size: 13px;
      display: flex;
      align-items: center;
      .txt {
        font-size: 13px;
        font-weight: 500;
        // color: #000;
        margin-left: 5px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
  .sidebar {
    background: #000;
    width: 190px;
    height: calc(100vh - 50px);
    overflow: auto;
    // position: fixed;

    ::v-deep .el-menu {
      .el-menu-item {
        height: 30px;
        line-height: 30px;
      }
      .is-active {
        border-right: 5px solid #409eff;
      }
    }
  }
  .noData {
    width: 0px;
    overflow: hidden;
    height: auto;
  }
  .main-no {
    margin-left: 190px;
  }
  .main {
    // flex: 1;
    width: 100%;

    height: 90vh;

    overflow: auto;
    .content {
      display: flex;
      .imgList {
        flex: 1;
      }
    }
    .search_box {
      padding: 20px 0;
      width: 80%;
      margin: 0 auto;
      display: flex;
      align-items: center;
      .history {
        width: 40px;
        height: 40px;
        background: #4587ff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 20px;
        cursor: pointer;
        img {
          width: 20px;
          height: 20px;
        }
      }
      .search {
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        flex: 1;
        // padding: 5px 5px 5px 0;

        border-radius: 3px;
        // outline: 1px solid #3683f2;
        box-shadow: 0 0 0 1px #ff6600;

        .search_left {
          .el-select {
            ::v-deep .el-input {
              .el-input__inner {
                width: 100px;
                border: 0px;
              }
            }
          }
        }

        .inp {
          height: 26px;
          border-left: 1px solid #e4e4e4;
          padding-left: 20px;
          font-size: $font-size-medium;
          font-family: PingFang SC;
          font-weight: 500;
          color: $color-text-default;
          flex: 1;
        }
        .search_btn {
          // width: 40px;
          height: 40px;
          padding: 0 10px;
          background: #ff6600;
          // @include backgroundGroup("~assets/images/idiom.png");
          @include flex-center();
          // border-radius: 0 5px 5px 0;
          cursor: pointer;
          > img:first-child {
            width: 52px;
            height: 22px;
            margin: 0 10px;
          }
          img:last-child {
            width: 20px;
            height: 20px;
          }
          // .search_icon {
          //   width: 17px;
          //   height: 17px;
          //   @include backgroundGroup("~assets/images/idiom.jpg");
          // }
        }
      }

      .keyword_box {
        margin-top: 20px;
        display: flex;

        .keyword_item {
          font-size: 14px;
          color: #666666;
          margin-right: 20px;
          cursor: pointer;
        }
      }
    }
    .kong {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      color: #999;
      font-size: 14px;
      margin: 200px 0;
      img {
        width: 150px;
        height: 150px;
      }
      // height: 100%;
    }
    .tips {
      text-align: center;
      font-size: 14px;
      color: #999;
      font-family: PingFang SC;
      height: 30px;
      line-height: 30px;
    }
    .images {
      margin-top: 20px;
      padding: 0 20px;
      box-sizing: border-box;
      -webkit-column-count: 3; //想要排成的列数
      -webkit-column-gap: 4px;
      // display: flex;
      // position: relative;
      // flex-direction: column;
      // flex-wrap: wrap;
      // height: calc(100vh - 120px);
      .item {
        // width: 200px;
        // height: auto;
        // position: absolute;
        position: relative;
        // overflow: hidden;

        .backgord {
          width: 100%;
          height: 0%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: rgba($color: #000000, $alpha: 0.6);
          // z-index: ;
          display: flex;
          align-items: center;
          justify-content: center;
          // display: none;
          -webkit-opacity: 0;
          transition: all 0.3s;
          .inset {
            background: #409eff;
            color: #fff;
          }
          .view {
            background: #409eff;
            color: #fff;
          }
          > div {
            cursor: pointer;
            margin: 0 3px;
            width: 50px;
            height: 50px;
            background: #fff;
            border-radius: 50%;
            line-height: 50px;
            text-align: center;
            // color: #999;
            font-size: 14px;
          }
        }
        img {
          width: 100%;
          margin: 1px 0;
          cursor: pointer;
        }
      }
      .item:hover .backgord {
        -webkit-opacity: 1;
        height: 70px;
      }
    }
  }
}
</style>
